import React from 'react';
import './App.css';
import Login from '../login/Login';
import Invitation from '../invitation/Invitation';
import GuestList from '../guestList/GuestList';
import Ltt from '../../util/Ltt';

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = { loggedIn: false, invitedGuests: [], loading: false };

    this.login = this.login.bind(this);
    this.setIsComing = this.setIsComing.bind(this);
  }

  login(code) {
    this.setState({ loading: true });
    Ltt.getGuestsByPasscode(code)
      .then(guests => {
        if (guests && guests.length > 0) {
          this.setState({ invitedGuests: guests, loggedIn: true, loading: false });
        } else {
          this.setState({ loading: false });
        }
      });
  }

  setIsComing(_guestid, _isComing) {
    if (_guestid && typeof _isComing === 'boolean') {
      const newGuestList = this.state.invitedGuests.map(g => {
        if (g.guestId === _guestid) {
          g.isComing = _isComing;
          Ltt.willCome(_guestid, _isComing);
        }
        return g;
      });
      this.setState({ invitedGuests: newGuestList });
    }
  }

  renderContent() {
    if (this.state.loggedIn) {
      return (
        <div className="App-content">
          <Invitation />
          <GuestList guests={this.state.invitedGuests} onChangeIsComing={this.setIsComing} />
        </div>
      );
    }
    return (
      <div>
        <Login onLogin={this.login} loading={this.state.loading} />
      </div>
    );
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <h1>XXX</h1>
          <h2>Les Trente Terribles</h2>
        </header>
        {this.renderContent()}
      </div>
    );
  }
}

export default App;