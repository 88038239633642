import React from 'react';
import './GuestList.css';
import Guest from '../guest/Guest';

let disguisedGuestList = [];

class GuestList extends React.Component {
    constructor(props) {
        super(props);
        this.disguiseGuestList();
    }

    disguiseGuestList() {
        for (let i = 1; i <= 30; i++) {
            const guest = this.props.guests.find(g => g.index === i);
            if (guest) {
                disguisedGuestList.push(guest);
            } else {
                disguisedGuestList.push({ guestId: i, name: "********************", isComing: true, index: i, passcode: undefined });
            }
        }
    }

    render() {
        return (
            <div className="GuestList">
                <h3>Gästeliste</h3>
                {disguisedGuestList.map(guest => {
                    const isRealGuest = this.props.guests.some(g => g.guestId === guest.guestId);
                    return <Guest key={guest.guestId} guest={guest} onChangeIsComing={this.props.onChangeIsComing} realGuest={isRealGuest} />
                })}
            </div>
        );
    }
}

export default GuestList;