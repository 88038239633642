import React from 'react';
import './Invitation.css';

class Invitation extends React.Component {
    render() {
        return (
            <div className="Invitation">
                <p><b><i>Les Trente Terribles</i></b> (aus dem franz. "Die schrecklichen Dreißig") ist eine Gruppe von 30 Personen, die gemeinsam den
                    <b> dreißigsten Geburtstag</b> von <b>Alexander Weingart</b> feiern wird.</p>
                <p>Du gehörst <b>selbstverständlich</b> dazu.</p>
                <h4>Ort und Zeit der Veranstaltung</h4>
                <p className="nomargin"><b>Samstag, 01.02.2020, 15 Uhr<br />Kaiser-Friedrich-Straße 238<br />47167 Duisburg<br />Neumühl</b></p>
                <p>Du gehörst zu einem mit größter Sorgfalt auserwähltem Personenkreis.<br />Bitte bestätige deine Teilnahme unten in der Gästeliste.</p>
                <p>Die Gästeliste ist <b>genau einen Monat</b> bis zum <b>15.01.2020</b> offen. Danach sind keine Änderungen mehr möglich.<br />Bitte stimme bis dahin ab.</p>
            </div>
        );
    }
}

export default Invitation;