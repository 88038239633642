import React from 'react';
import './Guest.css';
import Onoffswitch from '../onoffswitch/Onoffswitch';

class Guest extends React.Component {
    render() {
        let guestClassName = "Guest-Name";
        if (!this.props.guest.isComing) {
            guestClassName += " not-coming";
        }
        let switchClassName = "Guest-Switch";
        if (!this.props.realGuest) {
            switchClassName += " invisible";
        }
        return (
            <div className="Guest-Container">
                <div className="Guest-Index"><p>{this.props.guest.index}.</p></div>
                <div className={guestClassName}><p>{this.props.guest.name}</p></div>
                <div className={switchClassName}>
                    <Onoffswitch guestId={this.props.guest.guestId} value={this.props.guest.isComing} onChangeIsComing={this.props.onChangeIsComing} />
                </div>
            </div >
        );
    }
}

export default Guest;