import React from 'react';
import './Onoffswitch.css';

class Onoffswitch extends React.Component {
    constructor(props) {
        super(props);
        this.state = { isChecked: this.props.value };

        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.props.onChangeIsComing(this.props.guestId, !this.state.isChecked);
        this.setState({ isChecked: event.target.checked });
    }

    render() {
        let switchId = "myonoffswitch " + this.props.guestId;
        return (
            <div className="onoffswitch">
                <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id={switchId} checked={this.state.isChecked} onChange={this.handleChange} />
                <label className="onoffswitch-label" htmlFor={switchId}></label>
            </div>
        );
    }
}

export default Onoffswitch;