const baseUrl = 'https://europe-west1-les-trente-terribles.cloudfunctions.net/api';

const Ltt = {
    getGuestsByPasscode(passcode) {
        const endpoint = baseUrl + '/guests/' + passcode;
        return fetch(endpoint)
            .then(res => res.json())
            .then(jsonResp => {
                return jsonResp.map(g => ({
                    guestId: g.guestId,
                    name: g.name,
                    passcode: g.passcode,
                    isComing: g.isComing,
                    index: g.index
                }));
            })
            .catch(err => console.error(err));
    },

    willCome(guestId, isComing) {
        const endpoint = `${baseUrl}/guest/${guestId}/${isComing}`;
        return fetch(endpoint, { method: 'PUT' })
            .catch(err => console.error(err));
    }

};

export default Ltt;