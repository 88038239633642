import React from 'react';
import lock from './lock.svg';
import './Login.css';

class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = { value: "" };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    handleChange(event) {
        this.setState({ value: event.target.value });
    }

    handleSubmit(event) {
        this.props.onLogin(this.state.value);
        event.preventDefault();
        this.setState({ value: "" });
    }

    render() {
        let inputClassName = "PassCodeInput";
        let submitClassName = "PassCodeSubmit";
        if (this.props.loading) {
            inputClassName += " disabled";
            submitClassName += " disabled";
        }
        return (
            <div className="Login">
                <div className="Lock-Icon">
                    <img src={lock} alt="Lock-Icon" />
                </div>
                <form className="PassCodeForm" onSubmit={this.handleSubmit}>
                    <input
                        className={inputClassName}
                        type="password"
                        value={this.state.value}
                        onChange={this.handleChange}
                        disabled={this.props.loading}
                    />
                    <input
                        className={submitClassName}
                        type="submit"
                        value="Entrer"
                        disabled={this.props.loading}
                    />
                </form>
            </div>
        );
    }
}

export default Login;